
import Vue, { PropType } from "vue";

import { mdiAttachment } from "@mdi/js";
import http from "@/http";
import axios from "axios";

import { Attachment, Building, Snackbar } from "@/interfaces";

export interface DataType {
  files: File[];
}

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  data(): DataType {
    return {
      files: [],
    };
  },
  computed: {
    icon() {
      return { mdiAttachment };
    },
  },
  methods: {
    async click() {
      const { id } = this.building;

      try {
        const url = `buildings/${id}/attachments`;

        for (const file of this.files) {
          const formData = new FormData();

          formData.append("file", file);

          const { data } = await http.post<Attachment>(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        await this.$router.push(`/building/${this.building.id}/attachment`);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.code == "403") {
            const snackbar: Snackbar = {
              show: true,
              content: "権限がありません",
              color: "error",
            };

            this.$store.commit("setSnackbar", snackbar);
          }
        }
      }
    },
  },
});
